import { GrowthbookWrapper } from "~alao-growthbook/class";

export default function (_ctx, inject) {
  /**
   * @type {ModuleOptions} options - Nuxt module options
   */
  const options = JSON.parse('{"apiHost":"https://cdn.growthbook.io","debug":false,"clientKey":"sdk-wNs0PwGNPinOdqug"}') || {};

  const growthbook = GrowthbookWrapper.create(options);

  inject("growthbook", growthbook);
}
