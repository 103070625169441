
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue'

import RatingInput from '~/components/sections/rating/RatingInput.vue'
import { $api } from '~/plugins/axios'

export default defineComponent({
  name: 'AssistantFeedback',
  components: {
    RatingInput,
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const app = getCurrentInstance()

    const isSuccess = ref(false)
    const isError = ref(false)
    const isLoading = ref(false)

    const isShowForm = computed(() => !isSuccess.value && !isError.value)
    const isShowSuccessMessage = computed(() => isSuccess.value && !isError.value)
    const isShowErrorMessage = computed(() => !isSuccess.value && isError.value)

    const form = reactive({
      rating: 0,
      comment: '',
    })

    const onSubmit = async () => {
      isLoading.value = true
      try {
        const response = await $api.LemurService.sendFeedback({
          rating: form.rating,
          comment: form.comment,
          chatId: props.chatId,
        }, app?.proxy.$i18n.locale as string)

        isSuccess.value = true

        emit('submit', response)
      } catch (error) {
        isError.value = true
        app?.proxy.$sentry.captureException(error)
        emit('error', error)
      } finally {
        isLoading.value = false
      }
    }

    const animateRatingCounter = () => {
      if (form.rating < 5) {
        setTimeout(() => {
          form.rating = form.rating + 1
          animateRatingCounter()
        }, 120)
      }
    }

    onMounted(() => {
      setTimeout(animateRatingCounter, 300)
    })

    return {
      isLoading,
      form,
      isShowForm,
      isShowSuccessMessage,
      isShowErrorMessage,
      onSubmit,
    }
  },
})
