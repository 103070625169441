
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AssistantLayout',
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },
})
