import { OfferCategoryType, OfferProvider } from '@alao-frontend/core'
import { AnalyticsReadableOfferTypes, AssistantEventPayload, TrackEventOptions, WithEventProperty } from '../types'
import { GATrackEvent } from './ga.event'

// TODO: Define proper type for AssistanceEventMeta
export type AssistanceEventMeta = {
  eventName: string,
  eventAction: string,
  eventLabel: string,

  offerId: string,
  offerTitle: string,
  offerType: OfferCategoryType,
  providers: OfferProvider[],

  message?: string,
}

export class AssistantEvent extends GATrackEvent<AssistantEventPayload> {
  constructor (private meta: AssistanceEventMeta, protected options: TrackEventOptions) {
    super(options.provider, options.debug)
  }

  get payload (): WithEventProperty<AssistantEventPayload> {
    const {
      eventName,
    } = this.meta

    return Object.assign({},
      {
        data: this.data,
      },
      {
        event: 'GTMevent-v2',
        eventName,
        _clear: true,
      })
  }

  get data () {
    const {
      eventAction,
      message,
    } = this.meta

    return Object.assign({}, {
      event_category: 'assistant',
      event_action: eventAction,
      event_label: this.meta.eventLabel,
      plan_category: this.offerCategory,
      plan_name: this.meta.offerTitle,
      plan_id: this.meta.offerId,
      provider: this.meta.providers
        .map(provider => provider.name)
        .join(' | '),
    },
    message && {
      message,
    })
  }

  get offerCategory () {
    return AnalyticsReadableOfferTypes[this.meta.offerType]
  }
}
