
import { defineComponent } from 'vue'

import AppTransition from '~/components/AppTransition'

export default defineComponent({
  name: 'AssistantError',
  components: {
    AppTransition,
  },
  props: {
    details: {
      type: String,
      default: '',
    },
  },
})
