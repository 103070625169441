
import { computed, defineComponent, getCurrentInstance, PropType, ref } from 'vue'

import { AssistantCompact, AssistantFullscreen } from '~/components/AppAssistant/variants'
import AssistantContainer from '~/components/AppAssistant/AssistantContainer.vue'
import AssistantFeedbackModal from '~/components/AppAssistant/feeback/AssistantFeedbackModal.vue'
import { AssistantOfferContext } from '~/modules/assistant-v2'
import AppModal from '~/components/AppModal/AppModal.vue'

export default defineComponent({
  name: 'AppAssistant',
  components: {
    AppModal,
    AssistantCompact,
    AssistantFullscreen,
    AssistantContainer,
    AssistantFeedbackModal,
  },
  props: {
    variant: {
      type: String as PropType<'compact' | 'fullscreen'>,
      default: 'compact',
    },
  },
  setup (props) {
    const isOpen = ref(false)
    const isReady = ref(false)
    const isError = ref(false)
    const isMessageInputFocus = ref(false)
    const isFeedbackVisible = ref(false)
    const chatId = ref('')
    const offerContext = ref<AssistantOfferContext | undefined>()

    const app = getCurrentInstance()

    const assistantComponent = computed(() => {
      switch (props.variant) {
        case 'fullscreen':
          return AssistantFullscreen
        default:
          return AssistantCompact
      }
    })

    const onReady = () => {
      isReady.value = true
      isError.value = false
    }

    const onError = () => {
      isReady.value = false
      isError.value = true
    }

    const setOfferContext = (newOfferContext: AssistantOfferContext | undefined) => {
      offerContext.value = newOfferContext
    }

    const setOpenState = () => {
      isOpen.value = true

      // Atm we can only open the assistant by clicking a button
      app?.proxy.$analytics.newGTM.createAssistanceEvent(Object.assign({}, {
        eventName: 'assistant_click_open',
        eventAction: 'button_click',
        eventLabel: 'open',
      }, (offerContext.value as AssistantOfferContext))).send()
    }

    const setCloseState = () => {
      isOpen.value = false

      // Atm we can only close the assistant by clicking a close button
      app?.proxy.$analytics.newGTM.createAssistanceEvent(
        Object.assign({}, {
          eventName: 'assistant_click_close',
          eventAction: 'button_click',
          eventLabel: 'close',
        },
        (offerContext.value as AssistantOfferContext)),
      ).send()
    }

    const onRequestFeedback = (id: string) => {
      isFeedbackVisible.value = true
      chatId.value = id
    }

    const onMessageSend = (message: string) => {
      app?.proxy.$analytics.newGTM.createAssistanceEvent(
        Object.assign({}, {
          eventName: 'assistant_user_sent_message',
          eventAction: 'user_sent_message',
          eventLabel: message,
          message,
        },
        (offerContext.value as AssistantOfferContext)),
      ).send()
    }

    const onInputFocus = () => {
      isMessageInputFocus.value = true
    }

    const onInputBlur = () => {
      isMessageInputFocus.value = false
    }

    const onTabClick = (tabName: string) => {
      app?.proxy.$analytics.newGTM.createAssistanceEvent(
        Object.assign({}, {
          eventName: `assistant_nav_bttn_${tabName}`,
          eventAction: 'navigation_button_click',
          eventLabel: tabName,
        },
        (offerContext.value as AssistantOfferContext)),
      ).send()
    }

    const onOrderButtonClick = () => {
      app?.proxy.$analytics.newGTM.createAssistanceEvent(
        Object.assign({}, {
          eventName: 'assistant_begin_checkout',
          eventAction: 'begin_checkout',
          eventLabel: '',
        },
        (offerContext.value as AssistantOfferContext)),
      ).send()
    }

    return {
      assistantComponent,
      isOpen,
      isReady,
      isError,
      isMessageInputFocus,
      isFeedbackVisible,
      offerContext,
      chatId,
      setOpenState,
      setCloseState,
      onRequestFeedback,
      onReady,
      onError,
      setOfferContext,
      onMessageSend,
      onTabClick,
      onOrderButtonClick,
      onInputFocus,
      onInputBlur,
    }
  },
  watch: {
    $i18n: {
      handler () {
        this.$assistant.setLocale(this.$i18n.locale)
      },
      deep: true,
    },
  },

  mounted () {
    try {
      const containerEl = (this.$refs.containerRef as InstanceType<typeof AssistantContainer>).$el as HTMLDivElement

      if (!containerEl) {
        throw new Error('Assistant container element not found')
      }

      this.$assistant
        .mount(containerEl, this.$i18n.locale)
        .on('open', this.setOpenState.bind(this))
        .on('close', this.setCloseState.bind(this))
        .on('requestFeedback', this.onRequestFeedback.bind(this))
        .on('ready', this.onReady.bind(this))
        .on('error', this.onError.bind(this))
        .on('offerContextUpdate', this.setOfferContext.bind(this))
        .on('message', this.onMessageSend.bind(this))
        .on('input:focus', this.onInputFocus.bind(this))
        .on('input:blur', this.onInputBlur.bind(this))
        .on('tabClick', this.onTabClick.bind(this))
    } catch (e) {
      this.isError = true
    }
  },

  beforeDestroy () {
    this.$assistant.destroy()
    this.setOfferContext(undefined)
  },
})
