
import { defineComponent } from 'vue'

import AppTransition from '~/components/AppTransition'
import Loader from '~/components/Loader.vue'

export default defineComponent({
  name: 'AssistantLoader',
  components: {
    AppTransition,
    Loader,
  },
})
