
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AssistantContainer',
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
})
