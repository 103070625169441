
import { defineComponent } from 'vue'

import AppTransition from '~/components/AppTransition'

export default defineComponent({
  name: 'AssistantCompactFooter',
  components: {
    AppTransition,
  },
})
