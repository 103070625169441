import {
  GrowthBook,
  Options,
  Attributes,
  TrackingCallback,
  WidenPrimitives,
} from '@growthbook/growthbook'
import { ModuleOptions, GrowthbookRunOptions } from '../growthbook.d'

export class GrowthbookWrapper<
  AppFeatures extends Record<string, any> = Record<string, any>,
> {
  protected growthbook: GrowthBook<AppFeatures>

  constructor (options: Options) {
    this.growthbook = new GrowthBook<AppFeatures>(options)
  }

  public static create<
    AppFeatures extends Record<string, any> = Record<string, any>,
  >(
    options: ModuleOptions,
  ): GrowthbookWrapper<AppFeatures> {
    const instance = new GrowthbookWrapper<AppFeatures>({
      apiHost: options.apiHost,
      clientKey: options.clientKey,
      enableDevMode: options.debug,
      debug: options.debug,
    })

    return instance
  }

  // Returns the original GrowthBook instance
  public get client (): GrowthBook<AppFeatures> {
    return this.growthbook
  }

  public initialize ({ userId, trackingCallback }: GrowthbookRunOptions): void {
    if (userId) {
      this.assignUserId(userId)
    }

    if (trackingCallback) {
      this.setTrackingCallback(trackingCallback)
    }

    this.growthbook.init({
      streaming: true,
    })
  }

  public isFeatureEnabled<K extends string & keyof AppFeatures = string>(key: K): boolean {
    return this.growthbook.isOn(key)
  }

  public isFeatureDisabled<K extends string & keyof AppFeatures = string>(key: K): boolean {
    return this.growthbook.isOff(key)
  }

  public getFeatureValue<
    K extends string & keyof AppFeatures = string,
    V extends AppFeatures[K] = any,
  >(key: K, defaultValue: V): WidenPrimitives<V> {
    return this.growthbook.getFeatureValue(key, defaultValue)
  }

  public extendAttributes (attributes: Attributes): void {
    const currentAttributes = this.getAttibutes()

    this.setAttributes({
      ...currentAttributes,
      ...attributes,
    })
  }

  protected assignUserId (userId: string): void {
    this.extendAttributes({
      id: userId,
    })
  }

  private getAttibutes (): Attributes {
    return this.growthbook.getAttributes()
  }

  private setAttributes (attributes: Attributes): void {
    this.growthbook.setAttributes(attributes)
  }

  private setTrackingCallback (callback: TrackingCallback): void {
    this.growthbook.setTrackingCallback(callback)
  }
}
