import { ref, readonly, getCurrentInstance } from 'vue'
import { serializeCookie, parseCookie } from '@alao-frontend/utils'
import type { Context } from '@nuxt/types'
import { v4 as uuid } from 'uuid'

const COOKIE_NAME = '_auid'
const COOKIE_TTL_DAYS = 365

// TODO: Move to utils
const setCookie = (ctx: Context, name: string, value: string, expiresInDays: number) => {
  const serializedCookie = serializeCookie(name, value, {
    path: '/',
    expires: expiresInDays,
  })

  if (process.client) {
    // Set in browser
    document.cookie = serializedCookie
  } else if (process.server && ctx.res) {
    // Send Set-Cookie header from server side
    const prev = ctx.res.getHeader('Set-Cookie') as string | string[] | void
    let value: string | string[] = serializedCookie

    if (prev) {
      value = Array.isArray(prev)
        ? prev.concat(serializedCookie) as string[]
        : [prev, serializedCookie]
    }

    ctx.res.setHeader('Set-Cookie', value)
  }
}

// TODO: Move to utils
const getCookie = (ctx: Context, name: string) => {
  if (process.server && !ctx.req) {
    return
  }

  // Get and parse cookies
  const cookieStr = process.client ? document.cookie : ctx.req.headers.cookie
  const cookies = parseCookie(cookieStr || '') || {}

  return cookies[name]
}

export function useUserId () {
  const app = getCurrentInstance()

  const userId = ref<string>('')
  const cookieId = getCookie(app?.proxy.$ssrContext, COOKIE_NAME)

  if (cookieId) {
    userId.value = cookieId
  } else {
    const newId = uuid()
    setCookie(app?.proxy.$ssrContext, COOKIE_NAME, newId, COOKIE_TTL_DAYS)
    userId.value = newId
  }

  return {
    userId: readonly(userId),
  }
}
