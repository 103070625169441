
import { computed, defineComponent, getCurrentInstance, PropType, watch } from 'vue'

import { NamedOfferCategoryType } from '@alao-frontend/core'
import { AssistantOfferContext } from '~/modules/assistant-v2'

import AssistantLayout from '~/components/AppAssistant/AssistantLayout.vue'
import AssistantInner from '~/components/AppAssistant/AssistantInner.vue'
import AssistantLoader from '~/components/AppAssistant/AssistantLoader.vue'
import AssistantError from '~/components/AppAssistant/AssistantError.vue'
import AssistantOverlay from '~/components/AppAssistant/AssistantOverlay.vue'
import AssistantCompactFooter from '~/components/AppAssistant/variants/AssistantCompact/AssistantCompactFooter.vue'

export default defineComponent({
  name: 'AppAssistant',
  components: {
    AssistantLayout,
    AssistantOverlay,
    AssistantLoader,
    AssistantError,
    AssistantInner,
    AssistantCompactFooter,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
    isReady: {
      required: true,
      type: Boolean,
    },
    isError: {
      required: true,
      type: Boolean,
    },
    isMessageInputFocus: {
      required: true,
      type: Boolean,
    },
    offerContext: {
      type: Object as PropType<AssistantOfferContext>,
      default: null,
    },
  },

  setup (props) {
    const isMobileView = computed(() => process.client && window.innerWidth < 992)
    const isShowLoader = computed(() => !props.isReady && !props.isError)

    const app = getCurrentInstance()

    const orderUrl = computed(() => {
      if (!props.offerContext) {
        return ''
      }

      const namedType = NamedOfferCategoryType[props.offerContext.offerType]
      const pathName = `compare-${namedType}-slug`

      return app?.proxy.localePath({
        name: pathName,
        params: {
          slug: props.offerContext.offerSlug,
        },
      })
    })

    const isShowFooter = computed(() => {
      if (isMobileView.value && props.isMessageInputFocus) {
        return false
      }

      return props.isReady && !props.isError
    })

    const lock = () => process.client && document.body.classList.add('modal-open')
    const unlock = () => process.client && document.body.classList.remove('modal-open')

    watch(() => props.isOpen, (state) => {
      // Lock/unlock body scroll on mobile view only
      if (!isMobileView.value) {
        return
      }

      if (state) {
        lock()
      } else {
        unlock()
      }
    }, {
      immediate: true,
    })

    return {
      isMobileView,
      isShowFooter,
      isShowLoader,
      orderUrl,
    }
  },
})
