
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class RatingInput extends Vue {
  @ModelSync('value', 'input', { type: Number }) score!: number
  @Prop({ required: true }) readonly name!: string

  // eslint-disable-next-line camelcase
  $_veeValidate = {
    value: () => this.score,
  }

  private mouseOverIndex = 0

  get validNumberScore () {
    return this.score || 0
  }

  setScore (index: number) {
    this.score = index
  }

  onMouseOverButton (index: number) {
    this.mouseOverIndex = index
  }

  onMouseLeaveButton () {
    this.mouseOverIndex = 0
  }
}
