import { ApplicationService } from '~/services/core/application.service'
import { Partner } from '~/services/partner.service'

export type FeedbackPayload = {
  rating: number,
  chatId: string,
  comment: string,
}

export class LemurService extends ApplicationService {
  get baseUrl () {
    return this.runtimeConfig.BASE_URL
  }

  sendFeedback (feedback: FeedbackPayload, locale: string): Promise<Partner> {
    return this.post(
      `/lemur/api/v1/assistant/feedbacks?lang=${locale}`,
      feedback,
    )
  }
}
